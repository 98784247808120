<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>{{ $t('settings.page.pageDescription.pageSection') }}</h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageDescription.titleLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="page-title"
          v-model="pageDataComponent.name"
          debounce="500"
          @input="$emit('updatePageSettings', 'name', $event)"
        ></b-form-input>
        <b-form-text>
          {{ $t('settings.page.pageDescription.titleDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageDescription.permalinkLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="permalink"
          :disabled="slugSetToIndex && hasParentFolder"
          :class="(isInputInvalid) ? 'is-invalid' : ''"
          v-model="pageData.slug"
          debounce="500"
          @update="setSlug"
        ></b-form-input>
        <div
          v-if="isInputInvalid"
          class="invalid-feedback"
        >
          <span v-if="!$v.pageData.slug.duplicateSlug">
             {{ $t('settings.slugDuplicate') }}
          </span>
          <span v-if="!$v.pageData.slug.required">
            {{ $t('settings.notEmpty') }}
          </span>
          <span v-if="!$v.pageData.slug.checkForSlugify">
             {{ $t('settings.checkForSlugify') }}
          </span>
        </div>
        <b-form-text>
          {{ $t('settings.page.pageDescription.permalinkDesc') }}
        </b-form-text>
        <div v-if="hasParentFolder" :class="{'disabled-text': siblingSlugs.includes('index')}">
          <b-form-checkbox
            id="set-to-index"
            size="lg"
            v-model="slugSetToIndex"
            name="slugSetToIndex"
            :disabled="siblingSlugs.includes('index')"
          >
            {{ $t('settings.page.pageDescription.isIndexLabel') }}
          </b-form-checkbox>
          <b-form-text>
            {{ $t('settings.page.pageDescription.isIndexDesc') }}
          </b-form-text>
        </div>
        <div v-if="hasParentFolder && siblingSlugs.includes('index')">
          <b-form-text
            v-html="$t(
              'settings.page.pageDescription.indexAlreadyExists',
              { url: routingToIndexPage }
              )">
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageDescription.descriptionLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-textarea
          id="page-description"
          v-model="pageDataComponent.description"
          placeholder="Artikelbeschreibung..."
          rows="3"
          max-rows="6"
          debounce="500"
          @input="$emit('updatePageSettings', 'description', $event)"
        ></b-form-textarea>
        <b-form-text>
          {{ $t('settings.page.pageDescription.descriptionDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageDescription.categoriesLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-tags
          input-id="categories"
          v-model="pageDataComponent.keywords"
          class="mb-2"
          :placeholder="$t('settings.page.pageDescription.categoryPlaceholder')"
          addButtonText="hinzufügen"
          @input="$emit('updatePageSettings', 'keywords', $event)"
          @remove="$emit('updatePageSettings', 'keywords', $event)"
        ></b-form-tags>
        <b-form-text>
          {{ $t('settings.page.pageDescription.categoriesDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageDescription.tagsLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <v-selectize
          :options="options"
          :settings="groupSettings"
          v-model="selected"
          multiple
          @input="inputTags"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="is-shared-template"
          size="lg"
          v-model="pageDataComponent.is_shared_template"
          name="is_shared_template"
          @change="$emit('updatePageSettings', 'is_shared_template', $event)"
        >
          {{ $t('settings.page.pageDescription.isSharedPageLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.page.pageDescription.isSharedPageDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="pt-4 mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.page.pageDescription.titleImageSection') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
          :image-object="pageDataComponent.thumbnails.image"
          @upload-image-object="updateUploadImageObject"
        />
        <b-form-text
          v-html="$t('settings.page.pageDescription.titleImageFileDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="pt-4 mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>{{ $t('settings.page.pageDescription.visibilitySection') }}</h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4 d-none">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="set-cover-article"
          size="lg"
          v-model="pageDataComponent.featured"
          name="isCoverArticle"
          @change="$emit('updatePageSettings', 'featured', $event)"
        >
          {{ $t('settings.page.pageDescription.isCoverArticleLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.page.pageDescription.isCoverArticleDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4 pb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="hide-article"
          v-model="pageDataComponent.listed"
          name="hideArticle"
          size="lg"
          :value="false"
          :unchecked-value="true"
          @change="$emit('updatePageSettings', 'listed', $event)"
        >
          {{ $t('settings.page.pageDescription.hideArticleLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.page.pageDescription.hideArticleDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4 d-none">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="is-impressum"
          name="isImpressum"
          size="lg"
          value="true"
          unchecked-value="false"
        >
          {{ $t('settings.page.pageDescription.isImpressumLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.page.pageDescription.isImpressumDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4 d-none">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="is-data-policy"
          name="isDataPolicy"
          size="lg"
          value="true"
          unchecked-value="false"
        >
          {{ $t('settings.page.pageDescription.isDataPolicyLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.page.pageDescription.isDataPolicyDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="hasParentFolder">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="hide-from-search-engine"
          name="hideFromSearchEngine"
          size="lg"
          v-model="pageDataComponent.searchable"
          :value="false"
          :unchecked-value="true"
          @change="$emit('updatePageSettings', 'searchable', $event)"
        >
          {{ $t('settings.page.pageDescription.hideFromSearchEngineLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.page.pageDescription.hideFromSearchEngineDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'selectize/dist/css/selectize.default.css';

import ImageUpload from '@/components/helper/ImageUpload.vue';
import SetButtonDisabledInCache from '@/graphQlQueries/mutations/setButtonDisabledInCache';
import VSelectize from '@isneezy/vue-selectize';
import { validationMixin } from 'vuelidate';
import slugify from '@sindresorhus/slugify';
import { required } from 'vuelidate/lib/validators';

const duplicateSiblingsSlugValidator = (slugsOfAllSiblings) => (value) => {
  const slugifiedValue = slugify(value.trim());
  return !slugsOfAllSiblings.includes(slugifiedValue);
};

const checkForSlugify = (value) => {
  const slugifiedValue = slugify(value.trim());
  return slugifiedValue === value.trim();
};

export default {
  name: 'PageSettingsPageDescription',
  components: {
    ImageUpload,
    VSelectize,
  },
  mixins: [validationMixin],
  data: () => ({
    selected: '',
    options: [],
    slugSetToIndex: false,
    routerOfOldPage: null,
    routingToIndexPage: null,
    pageDataComponent: {
      name: null,
      description: null,
      keywords: [],
      featured: false,
      listed: false,
      slug: null,
      searchable: null,
      is_shared_template: null,
      thumbnails: {
        image: {
          url: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
          width: null,
          height: null,
        },
      },
    },
    groupSettings: {
      create: false,
      plugins: ['remove_button'],
    },
  }),
  props: ['pageData', 'pageTags', 'allTags', 'siblingSlugs', 'hasParentFolder', 'isSysAdmin'],
  created() {
    this.selected = this.pageTags;
    this.options = this.allTags;
    this.pageDataComponent = {
      ...this.pageDataComponent,
      ...this.pageData,
    };
    if (this.pageData.slug === 'index') {
      this.slugSetToIndex = true;
    }
    this.routingToIndexPage = this.$route.path
      .replace(`${this.pageData.slug}/page-settings`, 'index/page-settings');
  },
  validations() {
    return {
      pageData: {
        slug: {
          required,
          checkForSlugify,
          duplicateSlug: duplicateSiblingsSlugValidator(this.siblingSlugs),
        },
      },
    };
  },
  computed: {
    isInputInvalid() {
      if (this.$v.pageData.slug.$anyDirty) {
        if (
          !this.$v.pageData.slug.duplicateSlug
          || !this.$v.pageData.slug.checkForSlugify
          || !this.$v.pageData.slug.required
        ) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    slugSetToIndex(newValue) {
      if (newValue) {
        // set the slug to index
        if (this.pageData.name !== 'index') {
          this.pageData.slug = 'index';
          this.$emit('updatePageSettings', 'slug', 'index');
        }
      } else {
        // set the slug to the slugified title
        this.pageData.slug = slugify(this.pageData.name.toLowerCase().trim());
        this.$emit('updatePageSettings', 'slug', this.pageData.slug);
      }
    },
  },
  methods: {
    inputTags() {
      // eliminate duplicates
      this.selected = [...new Set(this.selected)];
      this.$emit('updatePageSettings', 'tags', this.selected);
    },
    updateUploadImageObject(imageObject) {
      this.pageData.thumbnails = { image: imageObject };
      this.$emit('updatePageSettings', 'thumbnails', this.pageData.thumbnails);
    },
    async setSlug(value) {
      this.$v.pageData.slug.$touch();
      if (!this.$v.pageData.slug.checkForSlugify) {
        this.pageData.slug = slugify(value.trim());
      }
      // check the slug to disable the button!
      if (this.isInputInvalid) {
        // disable the save button
        await this.$apollo.mutate({
          mutation: SetButtonDisabledInCache,
          variables: {
            isButtonDisabled: true,
          },
        });
      } else {
        await this.$apollo.mutate({
          mutation: SetButtonDisabledInCache,
          variables: {
            isButtonDisabled: false,
          },
        });
      }
      this.$emit('updatePageSettings', 'slug', slugify(value.trim()));
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .selectize-input {
  background: #5b6064;
  border-color: #303337;
}

.disabled-text {
  opacity: .2;
}

/deep/ input[disabled="disabled"] ~ label:after {
  cursor: not-allowed;
}
</style>
