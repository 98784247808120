var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("settings.page.pageDescription.pageSection"))
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageDescription.titleLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "page-title", debounce: "500" },
                on: {
                  input: function($event) {
                    return _vm.$emit("updatePageSettings", "name", $event)
                  }
                },
                model: {
                  value: _vm.pageDataComponent.name,
                  callback: function($$v) {
                    _vm.$set(_vm.pageDataComponent, "name", $$v)
                  },
                  expression: "pageDataComponent.name"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.page.pageDescription.titleDesc")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.page.pageDescription.permalinkLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                class: _vm.isInputInvalid ? "is-invalid" : "",
                attrs: {
                  id: "permalink",
                  disabled: _vm.slugSetToIndex && _vm.hasParentFolder,
                  debounce: "500"
                },
                on: { update: _vm.setSlug },
                model: {
                  value: _vm.pageData.slug,
                  callback: function($$v) {
                    _vm.$set(_vm.pageData, "slug", $$v)
                  },
                  expression: "pageData.slug"
                }
              }),
              _vm.isInputInvalid
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.pageData.slug.duplicateSlug
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.slugDuplicate")) + " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.pageData.slug.required
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.notEmpty")) + " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.pageData.slug.checkForSlugify
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.checkForSlugify")) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.permalinkDesc")
                    ) +
                    " "
                )
              ]),
              _vm.hasParentFolder
                ? _c(
                    "div",
                    {
                      class: {
                        "disabled-text": _vm.siblingSlugs.includes("index")
                      }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "set-to-index",
                            size: "lg",
                            name: "slugSetToIndex",
                            disabled: _vm.siblingSlugs.includes("index")
                          },
                          model: {
                            value: _vm.slugSetToIndex,
                            callback: function($$v) {
                              _vm.slugSetToIndex = $$v
                            },
                            expression: "slugSetToIndex"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "settings.page.pageDescription.isIndexLabel"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "settings.page.pageDescription.isIndexDesc"
                              )
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasParentFolder && _vm.siblingSlugs.includes("index")
                ? _c(
                    "div",
                    [
                      _c("b-form-text", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "settings.page.pageDescription.indexAlreadyExists",
                              { url: _vm.routingToIndexPage }
                            )
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.page.pageDescription.descriptionLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "page-description",
                  placeholder: "Artikelbeschreibung...",
                  rows: "3",
                  "max-rows": "6",
                  debounce: "500"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "updatePageSettings",
                      "description",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.pageDataComponent.description,
                  callback: function($$v) {
                    _vm.$set(_vm.pageDataComponent, "description", $$v)
                  },
                  expression: "pageDataComponent.description"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.descriptionDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.page.pageDescription.categoriesLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-tags", {
                staticClass: "mb-2",
                attrs: {
                  "input-id": "categories",
                  placeholder: _vm.$t(
                    "settings.page.pageDescription.categoryPlaceholder"
                  ),
                  addButtonText: "hinzufügen"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("updatePageSettings", "keywords", $event)
                  },
                  remove: function($event) {
                    return _vm.$emit("updatePageSettings", "keywords", $event)
                  }
                },
                model: {
                  value: _vm.pageDataComponent.keywords,
                  callback: function($$v) {
                    _vm.$set(_vm.pageDataComponent, "keywords", $$v)
                  },
                  expression: "pageDataComponent.keywords"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.categoriesDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageDescription.tagsLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("v-selectize", {
                attrs: {
                  options: _vm.options,
                  settings: _vm.groupSettings,
                  multiple: ""
                },
                on: { input: _vm.inputTags },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "is-shared-template",
                    size: "lg",
                    name: "is_shared_template"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "updatePageSettings",
                        "is_shared_template",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.pageDataComponent.is_shared_template,
                    callback: function($$v) {
                      _vm.$set(_vm.pageDataComponent, "is_shared_template", $$v)
                    },
                    expression: "pageDataComponent.is_shared_template"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.page.pageDescription.isSharedPageLabel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.isSharedPageDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-4 mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.page.pageDescription.titleImageSection")
                  ) +
                  " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("image-upload", {
                attrs: {
                  "image-object": _vm.pageDataComponent.thumbnails.image
                },
                on: { "upload-image-object": _vm.updateUploadImageObject }
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.page.pageDescription.titleImageFileDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-4 mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t("settings.page.pageDescription.visibilitySection")
                )
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 d-none" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "set-cover-article",
                    size: "lg",
                    name: "isCoverArticle"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("updatePageSettings", "featured", $event)
                    }
                  },
                  model: {
                    value: _vm.pageDataComponent.featured,
                    callback: function($$v) {
                      _vm.$set(_vm.pageDataComponent, "featured", $$v)
                    },
                    expression: "pageDataComponent.featured"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.page.pageDescription.isCoverArticleLabel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.isCoverArticleDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 pb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "hide-article",
                    name: "hideArticle",
                    size: "lg",
                    value: false,
                    "unchecked-value": true
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("updatePageSettings", "listed", $event)
                    }
                  },
                  model: {
                    value: _vm.pageDataComponent.listed,
                    callback: function($$v) {
                      _vm.$set(_vm.pageDataComponent, "listed", $$v)
                    },
                    expression: "pageDataComponent.listed"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("settings.page.pageDescription.hideArticleLabel")
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.hideArticleDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 d-none" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "is-impressum",
                    name: "isImpressum",
                    size: "lg",
                    value: "true",
                    "unchecked-value": "false"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("settings.page.pageDescription.isImpressumLabel")
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.isImpressumDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 d-none" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "is-data-policy",
                    name: "isDataPolicy",
                    size: "lg",
                    value: "true",
                    "unchecked-value": "false"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.page.pageDescription.isDataPolicyLabel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageDescription.isDataPolicyDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.hasParentFolder
        ? _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
              _c(
                "b-col",
                { attrs: { cols: "12", sm: "7" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "hide-from-search-engine",
                        name: "hideFromSearchEngine",
                        size: "lg",
                        value: false,
                        "unchecked-value": true
                      },
                      on: {
                        change: function($event) {
                          return _vm.$emit(
                            "updatePageSettings",
                            "searchable",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.pageDataComponent.searchable,
                        callback: function($$v) {
                          _vm.$set(_vm.pageDataComponent, "searchable", $$v)
                        },
                        expression: "pageDataComponent.searchable"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "settings.page.pageDescription.hideFromSearchEngineLabel"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("b-form-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.page.pageDescription.hideFromSearchEngineDesc"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }